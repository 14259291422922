import React from 'react'
import CardSummaryWithLoading from '../ui/card/CardSummaryWithLoading'

const DashboradNavBar = ({
    loadingTickets,
    allTicketCount,
    completTicketCount,
    penddingTicketCount,
    prossesingTicketCount,
}) => {
    return (
        <div className='h-32 flex gap-3 justify-between items-center'>
                <div className='flex-1 flex gap-3'>
                    <CardSummaryWithLoading
                        label={`اجمالي التذاكر`}
                        loading = {loadingTickets}
                        data={allTicketCount}
                        icon = '/assets/icons/icons8-ticket-96.png'
                        />
                    <CardSummaryWithLoading
                        label={`التذاكر المكتملة`}
                        loading = {loadingTickets}
                        data={completTicketCount}
                        icon = '/assets/icons/icons8-ticket-96.png'
                        />
                    <CardSummaryWithLoading
                        label={`التذاكر المعلقة`}
                        loading = {loadingTickets}
                        data={penddingTicketCount}
                        icon = '/assets/icons/icons8-ticket-96.png'
                        />
                    <CardSummaryWithLoading
                        label={`التذاكر الجاري تنفيذها`}
                        loading = {loadingTickets}
                        data={prossesingTicketCount}
                        icon = '/assets/icons/icons8-ticket-96.png'
                        />
                </div>

            </div>
    )
}

export default DashboradNavBar

import {useState,useEffect} from 'react'
import { CardHeaderWithActions, MwInputText, MwSelector, MwTextArea,MwInputFile, MwButton, MwSpinnerButton, AlertMsg } from '../components/ui'
import {SECTIONS,PRODUCTS,ALLOW_FIILE_TYPES} from '../constants/ticket'
import { useCurrent, usePost } from '../hooks';

const TicketCreate = () => {
    const {user,currentBranchId,currentCompanyId,currentTenantId} = useCurrent();
    const {data:resultUploads,loading:loadingUpload,postData:uploadFile} = usePost(false);
    const {data:resultSubmit,loading:loadingSubmit,postData:submitTicket} = usePost(false);
    const [fileLoading,setFileLoading] = useState(false);

    const [productSelected,setProductSelected] = useState();
    const [sectionSelected,setSectionSelected] = useState();
    const [subject,setSubject] = useState('');
    const [content,setContent] = useState('');
    const [attchment,setAttchment] = useState();
    const [errors,setErrors] = useState();
    const [submited,setSubmited] = useState(false);

    const uploadHandler = (e) => {
        const file = e.target.files[0] || null;
        console.log(file);
        if(file){
            if(ALLOW_FIILE_TYPES.find(type => type ===  file.type)){
                // setSelectedImage(URL.createObjectURL(file));
                const fileName = file.name;
                try {
                    console.log('file => ',file);
                    const _formData = new FormData();
                    _formData.append('myfile',file,fileName);
                    uploadFile(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/support`,_formData)
                } catch (error) {
                    console.error('Error uploading :', error);
                }

            }else{
                console.log('error  type:', file.type);
            }
        }
    }

    useEffect(() =>{
        if(resultUploads){
            resultUploads.data.location && setAttchment(resultUploads.data.location)
        }
    },[resultUploads]);



    const submitHandler = () => {
        setErrors({});
        let _errors = {};
        if(!sectionSelected?.value ){
            _errors.section = 'يجب اختيار القسم'
        }
        if(!productSelected?.value ){
            _errors.product = 'يجب اختيار الخمدمة / المنتج'
        }
        if(!content ){
            _errors.content = 'يجب ادخال وصف للتذكرة'
        }
        if(!subject ){
            _errors.subject = 'يجب ادخال عنوان للتذكرة '
        }
        if(!sectionSelected?.value || !productSelected.value || !content){
            setErrors(_errors);
            return;
        }

        const _data = 
            {
                customerName: user.name || null,
                customerId: user._id || null,
                email : user.email || null,
                mobile: user.mobile || null,
                subject: subject || null,
                content : content || null,
                sectionId : sectionSelected.value || null,
                ProductId: productSelected.value || null,
                attachments : attchment,
                status: "NEW",
                createdBy: user._id || null,
                branchId: currentBranchId,
                companyId: currentCompanyId,
                tenantId: currentTenantId
            }
        console.log(_data);
        submitTicket(`${process.env.REACT_APP_SUPPORT_API_BASE_URL}`,_data);
    }
    useEffect(() => {
        if(resultSubmit){
            console.log(resultSubmit)
            setSubmited(true);
        }
    },[resultSubmit]);

    return (
        <>
        {
            submited ? 
            <AlertMsg type={`Large`}>
                <span className='font-bold '>تم إرسال التذكرة الي فريق الدعم الفني ،</span>
                <span> سوف سيتم العمل علي مشكلتك في اقرب وقت  </span>
                <span>سعدنا بخدمتكم</span>
            </AlertMsg>:
            <div>
                <CardHeaderWithActions title={'انشاء تذكرة دعم فني جديدة'}>
                    {loadingSubmit ? <MwSpinnerButton/> :<MwButton onClick={submitHandler} type='saveBtn'>ارسال</MwButton>}
                </CardHeaderWithActions>
                <div className='flex flex-col gap-3'>
                    <MwSelector
                        className={` z-50`}
                        label={`المنتج / الخدمة`}
                        initalValue={`أختار النظام...`}
                        dataType={'labelValue'}
                        _data={PRODUCTS}
                        selectedItem={productSelected}
                        setSelectedItem={setProductSelected}
                        withAddNew={false}
                        invalid={!!errors?.product}
                        invalidMsg={errors?.product}
                        />
                    <MwSelector
                        className={` z-10`}
                        label={`القسم`}
                        initalValue={`أختار القسم...`}
                        dataType={'labelValue'}
                        _data={SECTIONS}
                        selectedItem={sectionSelected}
                        setSelectedItem={setSectionSelected}
                        withAddNew={false}
                        invalid={!!errors?.section}
                        invalidMsg={errors?.section}
                        />
                    <MwInputText
                        label ={`عنوان التذكرة`}
                        value = {subject}
                        onChange={(e) =>setSubject(e.target.value)}
                        invalid={!!errors?.subject}
                        invalidMsg={errors?.subject}
                        />
                    <MwTextArea
                        label ={` وصف المشكلة`}
                        rows={7}
                        value = {content}
                        onChange={(e) =>setContent(e.target.value)}
                        invalid={!!errors?.content}
                        invalidMsg={errors?.content}
                        />
                    <MwInputFile
                        label={'ارفق ملف'}
                        onChangeHandler={uploadHandler}
                        acceptTypes='*'
                        loading= {fileLoading}
                        />
                </div>
            </div>
        }
        </>
    )
}

export default TicketCreate
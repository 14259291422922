import React from 'react'
import { MwSpinnerButton } from '../ui'
import { Link } from 'react-router-dom'

const TicketInfo = ({item,loading}) => {
    return (
        <>
            {
            loading ? <MwSpinnerButton isFullCenter={true} withLabel={false}/> :
            <div className='flex gap-5 text-slate-600'>
                <div className='flex-1 flex flex-col gap-3'>
                    <span className='text-sm font-bold'>{item.subject}</span>
                    <span className='text-sm'>{item.content}</span>
                </div>
                <div className='w-60 border-s p-3 flex flex-col gap-2'>
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>حالة التذكرة</span>
                        <span className='text-xs'>{item.status}</span>
                    </div>
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>تاريخ الإنشاء</span>
                        <span className='text-xs'>{item.createdAt}</span>
                    </div>
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>بواسطة</span>
                        <span className='text-xs'>{item.customerName}</span>
                    </div>
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>القسم</span>
                        <span className='text-xs'>{item.sectionId}</span>
                    </div>
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>المنتج / الخدمة</span>
                        <span className='text-xs'>{item.ProductId}</span>
                    </div>
                    {
                        item?.attachments && <div className='flex flex-col'>
                            <span className='font-bold text-xs'>المرفقات</span>
                            <span className='text-xs'>
                                <Link className='primary-color' to={item.attachments} target='_blank'>{item.attachments.split('/')[item.attachments.split('/').length-1]}</Link>
                            </span>
                        </div>
                    }
                </div>
            </div>
        }
        </>
    )
}

export default TicketInfo
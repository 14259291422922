export const SECTIONS = [
    {
        label:'الصيانة والدعم الفني',
        value:'support',
        lang:'ar',
    },
    {
        label:'Support and Maintenance',
        value:'support',
        lang:'en',
    },
    {
        label:'المبيعات والفواتير',
        value:'support',
        lang:'ar',
    },
    {
        label:'Sales And Invoices',
        value:'salesAndInvoices',
        lang:'en',
    },
    {
        label:'التراخيص',
        value:'licences',
        lang:'ar',
    },
    {
        label:'Licences',
        value:'licences',
        lang:'en',
    },
    {
        label:'الاستفسارات والشكاوي',
        value:'complains',
        lang:'ar',
    },
    {
        label:'Complains',
        value:'complains',
        lang:'en',
    }
];

export const PRODUCTS = [
    {
        label: 'نظام المبيعات',
        value: 'sales',
        lang:'ar',
    },
    {
        label: 'Sales System',
        value: 'sales',
        lang:'en',
    },
    {
        label: 'نظام شئون الموظفين',
        value: 'hr',
        lang:'ar',
    },
    {
        label: 'Human Resources System',
        value: 'hr',
        lang:'en',
    },
    {
        label: 'نظام التقييم',
        value: 'rating',
        lang:'ar',
    },
    {
        label: 'Rating System',
        value: 'rating',
        lang:'en',
    },
];

export const STATUS =[
    {
        label:'NEW',
        value: 'new',
        lang:'en'
    },
    {
        label:'جديدة',
        value: 'NEW',
        lang:'ar'
    },
    {
        label:'Review',
        value: 'review',
        lang:'en'
    },
    {
        label:'تحت المراجعة',
        value: 'review',
        lang:'en'
    },
    {
        label:'Pendding',
        value: 'pendding',
        lang:'en'
    },
    {
        label:'معلقة',
        value: 'pendding',
        lang:'en'
    },
    {
        label:'Prossesing',
        value: 'prossesing',
        lang:'en'
    },
    {
        label:'جاري التنفيذ',
        value: 'prossesing',
        lang:'ar'
    },
    {
        label:'Completely',
        value: 'completely',
        lang:'en'
    },
    {
        label:'مكتملة',
        value: 'completely',
        lang:'ar'
    },
    {
        label:'Closed',
        value: 'closed',
        lang:'en'
    },
    {
        label:'مغلقة',
        value: 'closed',
        lang:'ar'
    },
    {
        label:'Canceled',
        value: 'canceled',
        lang:'en'
    },
    {
        label:'ملغي',
        value: 'canceled',
        lang:'ar'
    },
]
export const ALLOW_FIILE_TYPES = [
    "image/png", 
    "image/jpg", 
    "image/jpeg", 
    "image/gif",
    "application/pdf", 
    "application/msword", 
    "application/docx"
];

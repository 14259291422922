import React from 'react'
import TicketDetailsListItem from './TicketDetailsListItem'
import { MwSpinnerButton } from '../ui'

const TicketDetailsList = ({data,loading}) => {
    return (
        <>
            {
                loading ? <MwSpinnerButton isFullCenter={true} withLabel={false}/> :
                <div>
                    {
                        data?.map((item, index) =>(<TicketDetailsListItem 
                            key={item._id}
                            item={item}
                                />
                            ))
                    }
                </div>
            }
        </>
    )
}

export default TicketDetailsList
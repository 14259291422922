import React, { useEffect } from 'react'
import { useCurrent, useFetch } from '../hooks'
import { CardHeaderWithActions, MwButton } from '../components/ui';
import TicketsList from '../components/tickets/TicketsList';
import { useNavigate, useParams } from 'react-router-dom';

const TicketsManage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:resultTickets,loading:loadingTickets} = useFetch(`${process.env.REACT_APP_SUPPORT_API_BASE_URL}/tenant/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);

    useEffect(() => {
        if(resultTickets){
            console.log(resultTickets)
        }
    },[resultTickets]);

    return (
    <div>
        <CardHeaderWithActions title={`تذاكر الدعم  الفني`}>
            <MwButton type='saveBtn' onClick={()=>navigate(`/${params.tenant}/tickets/add`)} >انشاء تذكرة دعم فني</MwButton>
        </CardHeaderWithActions>
        <TicketsList data={resultTickets} loading={loadingTickets}/>
    </div>
    )
}

export default TicketsManage
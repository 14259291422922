import React,{useState,useEffect} from 'react'
import { FaReplyAll } from "react-icons/fa";
import { MwButton, MwInputFile, MwSpinnerButton, MwTextArea } from '../ui';
import { useCurrent, usePost } from '../../hooks';
import {ALLOW_FIILE_TYPES} from '../../constants/ticket'


const CreateTicketDetails = ({ticketID,callBackOnCreated}) => {
    const {user} = useCurrent();
    const {data:resultUploads,loading:loadingUpload,postData:uploadFile} = usePost(false);
    const {data:resultSubmit,loading:loadingSubmit,postData:submitTicket} = usePost(false);
    const [fileLoading,setFileLoading] = useState(false);
    const [submited,setSubmited] = useState(false);

    const [content,setContent] = useState();
    const [attchment,setAttchment] = useState();
    const [errors,setErrors] = useState();

    const uploadHandler = (e) => {
        const file = e.target.files[0] || null;
        console.log(file);
        if(file){
            if(ALLOW_FIILE_TYPES.find(type => type ===  file.type)){
                // setSelectedImage(URL.createObjectURL(file));
                const fileName = file.name;
                try {
                    console.log('file => ',file);
                    const _formData = new FormData();
                    _formData.append('myfile',file,fileName);
                    uploadFile(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/support`,_formData)
                } catch (error) {
                    console.error('Error uploading :', error);
                }

            }else{
                console.log('error  type:', file.type);
            }
        }
    }

    useEffect(() =>{
        if(resultUploads){
            resultUploads.data.location && setAttchment(resultUploads.data.location)
        }
    },[resultUploads]);




    const submitHandler = () => {
        setErrors({});
        if(!content){
            setErrors((state)=>({ ...state, content:'يجب ادخال تعليق' }));
            return;
        }
        const _data = 
            {
                ticketId: ticketID,
                content : content || null,
                attachments : attchment,
                createdBy: user._id || null,
            }
        console.log(_data);
        submitTicket(`${process.env.REACT_APP_SUPPORT_API_BASE_URL}/details`,_data);
    }
    useEffect(() => {
        if(resultSubmit){
            console.log(resultSubmit)
            setSubmited(true);
            callBackOnCreated();
        }
    },[resultSubmit]);
    
    return (
        <div className='bg-slate-200 p-5 my-3 rounded-xl'>
            <div className='pb-2 text-sm text-slate-500 flex items-center gap-1'>
            <FaReplyAll />
                أضف تعليق جديد</div>
            <MwTextArea
                    rows={3}
                    value = {content}
                    onChange={(e) =>setContent(e.target.value)}
                    invalid ={!!errors?.content}
                    invalidMsg ={errors?.content}
                />
            <div className='flex justify-between'>
                <MwInputFile
                    label={'ارفق ملف'}
                    onChangeHandler={uploadHandler}
                    acceptTypes='*'
                    loading= {fileLoading}
                    />
                {loadingSubmit ? <MwSpinnerButton/> :<MwButton onClick={submitHandler} classNameCustom='mt-3' type='secondary'>
                        <span>إرسال التعليق</span>
                        <FaReplyAll />
                    </MwButton>}
                
                </div>
        </div>
    )
}

export default CreateTicketDetails
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../hooks';
import TicketInfo from '../components/tickets/TicketInfo';
import TicketDetailsList from '../components/tickets/TicketDetailsList';
import CreateTicketDetails from '../components/tickets/CreateTicketDetails';

const TicketsShow = () => {
    const params = useParams();
    const id = params.id;

    const {data:ticketResult,loading:ticketLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_SUPPORT_API_BASE_URL}/${id}`);
    
    useEffect(() =>{
        if(ticketResult){
            console.log(ticketResult);
        }
    },[ticketResult]);

    const callBackOnCreated = () =>{
        refreshHandler()
    }
    return (
        <>
            <div>
                <TicketInfo loading={ticketLoading} item={ticketResult?.data}/>
                <hr className='my-3 mx-1' />
                { ticketResult && <CreateTicketDetails ticketID ={ticketResult?.data?._id} callBackOnCreated = {callBackOnCreated} />}
                { ticketResult && <TicketDetailsList loading={ticketLoading}  data={ticketResult?.data?.details}  /> }
            </div>
        </>
    )
}

export default TicketsShow
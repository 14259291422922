import React, { useEffect,useState } from 'react'
import { useCurrent, useFetch } from '../hooks';
import DashboradNavBar from '../components/dashborad/DashboradNavBar';
import { Link, useParams } from 'react-router-dom';
import MonthlyLineChart from '../components/dashborad/MonthlyLineChart';
import { MwButton } from '../components/ui';
import { FaSquarePhone } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { MdSupport } from "react-icons/md";

// import {useCurrent} from '../hooks'

const DashBoard = () => {
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const tenantUsername = useParams()?.tenant;

    const [allTicketCount,setAllTicketCount] = useState(0)
    const [completTicketCount,setCompleteTicketCount] = useState(0)
    const [penddingTicketCount,setPenddingTicketCount] = useState(0)
    const [prossesingTicketCount,setProssesingTicketCount] = useState(0)
    
    const {data:resultTickets,loading:loadingTickets} = useFetch(`${process.env.REACT_APP_SUPPORT_API_BASE_URL}/tenant/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const [monthlyList,setMonthlyList] = useState([]);


    const monthlyChartHandler = () => {
        let _monthly=[];
            
        [1,2,3,4,5,6,7,8,9,10,11,12].forEach(month =>{
            let sumMonthly = 0;
            resultTickets.data.map( res => {
                const _date = new Date(res.createdAt);
                if( _date.getMonth() + 1 === month ){
                    if(res){
                        sumMonthly += 1 || 0;
                    }
                }
            })
            _monthly.push(sumMonthly)
        })
        setMonthlyList(_monthly);
        console.log( '_monthlyRating => ',_monthly)
    }
    useEffect(() =>{
        if(resultTickets){
            setAllTicketCount(resultTickets?.data.length);
            setCompleteTicketCount(resultTickets?.data.filter(t => t.status === 'completely').length);
            setPenddingTicketCount(resultTickets?.data.filter(t => t.status === 'pendding').length);
            setProssesingTicketCount(resultTickets?.data.filter(t => t.status === 'prossesing').length);
            monthlyChartHandler();
        }
    },[resultTickets]);

    return (
        <>
            <div className='flex flex-col gap-5'>
                <div className='flex gap-3  justify-between items-center'>
                    <div className='flex flex-col'>
                        <div className='text-2xl'>
                            <span>الدعم <span>والمساعدة</span></span>
                        </div>
                        <div className='text-sm w-4/6'>
                            <span>أهلا بك عميلنا العزيز في الدعم الفني لنظام أبسط أعمال</span>
                            <span> جاهزين لخدمتكم في اي وقت ونامل ان تنال الخدمة رضاكم </span>
                        </div>
                    </div>
                    <div className='text-slate-600'>
                        <Link to={`/${tenantUsername}/tickets/add`}>
                            <MwButton type='secondary' classNameCustom=''>
                            <MdSupport size={18} />
                                أنشاء تذكرة دعم فني جديدة
                                </MwButton> 
                        </Link>
                        <div className='flex gap-1 pt-2  font-bold text-slate-500  justify-between'>
                            <FaSquarePhone size={18} />
                            <span dir='ltr'>053 96 11 96 0</span>
                        </div>
                        <div className='flex gap-1   font-bold text-slate-500 justify-between'>
                            <MdOutlineEmail size={18} />
                            <span>support@absat.org</span>
                        </div>
                    </div>
                </div>
                <DashboradNavBar
                    loadingTickets ={loadingTickets}
                    allTicketCount = {allTicketCount}
                    completTicketCount = {completTicketCount}
                    penddingTicketCount ={penddingTicketCount}
                    prossesingTicketCount = {prossesingTicketCount}
                    />
                <MonthlyLineChart
                    tenantUsername = {tenantUsername}
                    loading = {loadingTickets}
                    monthlyList = {monthlyList}
                    label = 'تذاكر الدعم علي مدار العام الحالي'
                />
            </div>

        </>
    )
}

export default DashBoard
import React from 'react'
import { Link } from 'react-router-dom'

const TicketDetailsListItem = ({item}) => {
    return (
        <div className='w-full p-3 my-1 bg-slate-100 text-slate-600 text-sm rounded-xl flex gap-3 justify-between'>
            <div className='flex-1'>{item.content}</div>
            <div className='text-xs w-60 border-s p-3 flex flex-col gap-2'>
                <div className='flex flex-col'>
                    <span className='font-bold text-xs'>تاريخ الإنشاء</span>
                    <span className='text-xs'>{item.createdAt}</span>
                </div>
                {
                    item?.createdBy &&
                    <div className='flex flex-col'>
                        <span className='font-bold text-xs'>بواسطة</span>
                        <span className='text-xs'>{item.createdBy}</span>
                    </div>
                }
                {
                    item?.attachments && <div className='flex flex-col'>
                        <span className='font-bold text-xs'>المرفقات</span>
                        <span className='text-xs'>
                            <Link className='primary-color' to={item.attachments} target='_blank'>{item.attachments.split('/')[item.attachments.split('/').length-1]}</Link>
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default TicketDetailsListItem
import React,{useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import { useCurrent } from '../../hooks';

const MwSelector = ({label,_data,dataType,onClickNew,setSelectedItem,selectedItem,initalValue='Choose',withLabel=true,withAddNew=true,className,styleList,invalid,invalidMsg}) => {
    const [inputValue,setInputValue] = useState('');
    const {currentLangId} = useCurrent()
    const [open,setOpen] = useState(false);

    const onSelectedHandler = (item) => {
        setSelectedItem({...item,label:item.label})
        setOpen(false);
        setInputValue("")
    }
    const dataList = _data &&  _data?.map((item,index) => {
    
        if(dataType === 'labelValue' ){
            if(item?.lang === currentLangId )
                return <div 
                            className={`px-4 py-1 border-t cursor-default text-sm hover:bg-slate-200 hover:font-bold ${item?.label?.toLowerCase().startsWith(inputValue) ? 'block' : 'hidden'}`}
                            key={index}
                            onClick={()=>onSelectedHandler(item)}
                            >
                            {item.label}
                        </div>
        }

    })
    return (
        <>
            <div className={` z-10 ${open && ' relative '} ${className}`}>
                <label className='text-xs text-slate-400'> {label} </label>
                <div
                    onClick={()=>setOpen(!open)}
                    className={`cursor-default flex text-xs bg-white text-slate-600 w-full h-8 px-2 items-center justify-between rounded  ${ invalid && 'border border-red-500'}  ${styleList} `} >
                        {(selectedItem && selectedItem !== 'sub'  ? selectedItem.label : initalValue) || initalValue}
                        <BiChevronDown size={20}/>
                </div>
                <ul className={`bg-slate-200 start-0  overflow-y-auto absolute w-full  rounded-b-lg   ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>
                    <div className='sticky  top-0 p-3 '>
                        { 
                        withAddNew && <div className='text-end'>
                            <div className='text-sky-700 absolute top-4 end-4'  
                            onClick={()=>{ 
                                onClickNew()
                                setOpen(false) 
                            }}
                            > <MdAddBox size={24}/> </div>
                        </div>
                        }
                        <div className='flex items-center justify-between  px-2 bg-white rounded '>
                            <AiOutlineSearch size={18} className='text-gray-600' />
                            <input 
                                type="text"
                                placeholder= {label}
                                className='placeholder:text-gray-400 p-2 outline-none text-xs p-2 w-full bg-white'
                                value={inputValue}
                                onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                                />
                        </div>
                    </div>
                    <div className='mb-3 '>
                        {dataList}
                    </div>
                </ul>
                { invalidMsg && <div className='text-xs text-red-500'>{invalidMsg}</div> }
            </div>
        </>
    )
}

export default MwSelector
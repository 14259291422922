import React from 'react'
import { MwSpinnerButton, NoDataFound } from '../ui'
import TicketsListItem from './TicketsListItem'
import { Link, useParams } from 'react-router-dom'
import { useCurrent } from '../../hooks'

const TicketsList = ({data,loading}) => {
    const {currentLangId} = useCurrent();
    const params = useParams();
    return (
        <>
            {
                loading ? <MwSpinnerButton withLabel={false} isFullCenter={true}/> :
                data?.data?.length > 0 ? 
                <div className='text-sm '>
                    <div className='flex items-center justify-between text-slate-500  bg-slate-200 rounded-t-xl  p-3  '>
                        <div className='w-8 '>#</div>
                        <div className='w-20 '>رقم التذكرة</div>
                        <div className='flex-1'>عنوان التذكرة</div>
                        <div className='text-center w-28'> القسم </div>
                        <div className='text-center w-28'> المنتج </div>
                        <div className='text-center w-24' >تاريخ الانشاء</div>
                        <div className='text-center w-16'> الحالة </div>
                    </div>
                    {
                        data.data.map((item,index) => (
                            <Link key={item._id} to={`/${params.tenant}/tickets/show/${item._id}`}>
                                <TicketsListItem lang={currentLangId}  item={item} index={index} />
                            </Link>
                        ))
                    }
                </div>
                : <NoDataFound msg={'لا يوجد تذاكر دعم'} />
            }
        </>
    )
}

export default TicketsList
import React from 'react'
import MwSpinnerButton from '../MwSpinnerButton'

const CardSummaryWithLoading = ({label,loading,data,children,icon,reverse=false}) => {
    return (
        <>
        {
            !reverse ?
                <div className='text-sm text-slate-500 h-full p-3 flex-1 flex flex-col justify-between items-center gap-2 rounded-xl bg-slate-100'>
                    {icon && <img src={icon} className='w-7' alt={label} />}
                    <div className=' text-center '>{label}</div>
                    <div className='text-center font-bold w-full'>{ loading ? 
                    <MwSpinnerButton isFullCenter={true} withLabel={false}/> : children ? children :<div className='w-full border-t border-slate-300 pt-2' >{data}</div>}</div>
                </div> :
                <div className='text-sm text-slate-500  h-full p-3 flex-1 flex flex-col justify-evenly items-center gap-2 rounded-xl bg-slate-100'>
                    {icon && <img src={icon} className='w-8' alt={label} />}
                    <div className='text-center font-bold w-full'>{ loading ?  <MwSpinnerButton withLabel={false}/> : children ? children :<div className='w-full border-t border-slate-300 pt-2' >{data}</div>}</div>
                    <div className=' text-center  '>{label}</div>
                </div>
        }
        </>
        
    )
}

export default CardSummaryWithLoading
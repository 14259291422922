import React from 'react'
import {SECTIONS,PRODUCTS,STATUS} from '../../constants/ticket.js'

const TicketsListItem = ({item,index,lang}) => {
    const createDate = new Date(item.createdAt).toLocaleDateString("en-US");
    const _section = SECTIONS.filter(s => (s.value === item.sectionId && s.lang === lang) )[0]?.label;
    const _product = PRODUCTS.filter(s => (s.value === item.ProductId && s.lang === lang) )[0]?.label;
    const _status = STATUS.filter(s => (s.value === item.status && s.lang === lang) )[0]?.label;
    return (
        <div className='flex items-center justify-between text-xs text-slate-600 bg-slate-50 hover:bg-slate-100 hover:font-bold border-b   p-3  '>
            <div className='w-8'>{index+1}</div>
            <div className='w-20'>{item._id.substring(1,5)}</div>
            <div className='flex-1'>{item.subject}</div>
            <div className='text-center w-28'> {_section} </div>
            <div className='text-center w-28'> {_product} </div>
            <div className='text-center w-24'> {createDate} </div>
            <div className='text-center w-16'> {_status} </div>
        </div>
    )
}

export default TicketsListItem